const waypoints = require('waypoints/lib/noframework.waypoints.min.js');
const sticky = require('waypoints/lib/shortcuts/sticky.min.js');

class SitewideHeader extends HTMLElement {
  constructor() {
    super();
    this.searchKeyboardActions = this.searchKeyboardActions.bind(this);
    this.keyboardAcctions = this.keyboardAcctions.bind(this);
    this.init();
  }

  changeHeaderBackgroundColor() {
    const header = document.querySelector('.sitewide-header');
    if (window.scrollY > 0) {
      header.classList.add('sitewide-header--scrolled');
    } else {
      header.classList.remove('sitewide-header--scrolled');
    }
  }

  keyboardAcctions(e) {
    const menuToggle = this.querySelector('.sitewide-header__toggle');
    
    if (e.keyCode === 27) {
      this.expandMenu();
      this.toggleMenu(menuToggle, true);
      menuToggle.focus();
      return;
    }

    const menu = this.querySelector('.sitewide-header__off-canvas-menu');
    const closeLink = this.querySelector('.sitewide-header__close-menu');
    const links = menu.querySelectorAll('a','div');
    const firstElement = links[0];
    const lastElement = links[links.length - 1];


    if (e.key === 'Tab' || e.keyCode === 9) {
      
      // Tab key is pressed
      if (e.shiftKey) {
        // Shift key is also pressed (reverse order)
        if (document.activeElement === firstElement) {
          // If the first link is focused, move focus to the last link
          e.preventDefault();
          closeLink.focus(); //lastElement.focus();
        }
      } else {
        // Tab key is pressed (normal order)
        if (document.activeElement === lastElement) {
          // If the last link is focused, move focus to the first link
          e.preventDefault();
          closeLink.focus(); //firstElement.focus();
        }
      }
    }

    
  }

  searchKeyboardActions(e) {
    const search = this.querySelector('.sitewide-header__search-container');
    const searchInput = search.querySelector('input');
    const submit = search.querySelector('button');

    if (e.key === 'Escape') {
      this.expandSearch(e);
      return;
    }

    if (e.key === 'Tab' || e.keyCode === 9) {
      // Tab key is pressed
      if (e.shiftKey) {
        // Shift key is also pressed (reverse order)
        if (document.activeElement === searchInput) {
          // If the first link is focused, move focus to the last link
          e.preventDefault();
          submit.focus();
        }
      } else {
        // Tab key is pressed (normal order)
        if (document.activeElement === submit) {
          // If the last link is focused, move focus to the first link
          e.preventDefault();
          searchInput.focus();
        }
      }
    }
  }

  expandSearch(e) {
    const searchToggle = this.querySelector('.sitewide-header__search-toggle');
    const search = this.querySelector('.sitewide-header__search-container');
    const isOpen = search.classList.contains('sitewide-header__search-container--expanded');

    if (!isOpen) {
      search.querySelector('input').focus();
      searchToggle.setAttribute('aria-expanded', 'true');
      searchToggle.setAttribute('aria-label', 'Close search');
      search.classList.add('visible');
      search.querySelector('input').focus();
      document.addEventListener('keydown', this.searchKeyboardActions);
    } else {
      searchToggle.focus();
      searchToggle.setAttribute('aria-expanded', 'false');
      searchToggle.setAttribute('aria-label', 'Open search');
      document.removeEventListener('keydown', this.searchKeyboardActions);
      setTimeout(() => {
        search.classList.remove('visible');
      }, 400);
    }

    search.classList.toggle('sitewide-header__search-container--expanded');
    this.classList.toggle('sitewide-header--expanded');
  }

  closeSearch() {
    const search = this.querySelector('.sitewide-header__search-container');
    const searchToggle = this.querySelector('.sitewide-header__search-toggle');
    search.classList.remove('visible');
    search.classList.remove('sitewide-header__search-container--expanded');
    this.classList.remove('sitewide-header--expanded');
    searchToggle.setAttribute('aria-expanded', 'false');
    searchToggle.setAttribute('aria-label', 'Open search');
  }

  expandMenu() {
    this.closeSearch();
    const menu = this.querySelector('.sitewide-header__off-canvas-menu');
    const isOpen = menu.classList.contains('sitewide-header__off-canvas-menu--expanded');
    document.body.classList.toggle('no-scroll');

    if (!isOpen) {
      menu.classList.add('visible');
      this.classList.add('sitewide-header--expanded');
      setTimeout(() => {
        menu.classList.add('sitewide-header__off-canvas-menu--expanded');
        menu.querySelector('a:first-of-type').focus();
      }, 200);
      document.addEventListener('keydown', this.keyboardAcctions);
    } else {
      this.classList.remove('sitewide-header--expanded');
      menu.classList.remove('sitewide-header__off-canvas-menu--expanded');
      document.removeEventListener('keydown', this.keyboardAcctions);
      setTimeout(() => {
        menu.classList.remove('visible');
      }, 600);
    }
  }

  toggleMenu(menuToggle, isOpen) {
    if (isOpen) {
      menuToggle.classList.remove('sitewide-header--open');
      menuToggle.setAttribute('aria-expanded', 'false');
      menuToggle.setAttribute('aria-label', 'Close menu');
    } else {
      menuToggle.classList.add('sitewide-header--open');
      menuToggle.setAttribute('aria-expanded', 'true');
      menuToggle.setAttribute('aria-label', 'Open menu');
      menuToggle.focus();
    }
  }

  init()  {
    const menuToggle = this.querySelector('.sitewide-header__toggle');
    const closeMenu = this.querySelector('.sitewide-header__close-menu');
    const searchToggle = this.querySelector('.sitewide-header__search-toggle');

    searchToggle.addEventListener('click', this.expandSearch.bind(this));

    closeMenu.addEventListener('click', (e) => {
      const isOpen = menuToggle.classList.contains('sitewide-header--open');
      this.expandMenu();
      this.toggleMenu(menuToggle, isOpen);
    });

    menuToggle.addEventListener('click', (e) => {
      const isOpen = menuToggle.classList.contains('sitewide-header--open');
      this.expandMenu();
      this.toggleMenu(menuToggle, isOpen);
    });
    
    if (this.getAttribute('data-is-front') === 'true') {
      window.addEventListener("scroll", this.changeHeaderBackgroundColor);
      return;
    }

    new Waypoint.Sticky({ element: this });
  }
}

customElements.define('sitewide-header', SitewideHeader, { extends: 'header' });